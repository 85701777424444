import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout/layout'

const VinoPage = () => (
  <Layout>
    <h1>Corazón Spumante Rosado</h1>
    <h2>Vino espumoso rosado</h2>
    <p>100% Bobal</p>
    <p>Corazón Spumante Rosado es un vino espumoso elaborado con la variedad Bobal.</p>
    <Link to="/vinos">Back</Link>
  </Layout>
)

export default VinoPage
